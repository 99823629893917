<template>
  <div id="alku" class="PageArea NormalPaddings">
    <h1>Tootoo</h1>
    <p>
      Tämä on erään hengen kirjoittama satu menninkäisistä ja heidän
      seikkailuistaan. Satu on raakaversio, jota ei ole vielä painettu
      satukirjaksi.
    </p>
    <p>Yksinoikeus tekstiin T:mi Enkelin Esiliina.</p>
    <img src="../assets/tootoo.jpg" alt="Kuva Tootoosta" />
    <figcaption>Tämä on 8-vuotiaan pojan näkemys Tootoosta.</figcaption>
    <h2>Sisällysluettelo</h2>
    <ul>
      <li><a href="/kappale/tootoo/0">Johdanto</a></li>
      <li><a href="/kappale/tootoo/1">Matka alkakoon</a></li>
      <li>
        <a href="/kappale/tootoo/2">Tootoon ja Toffeen veneen rakennus</a>
      </li>
      <li><a href="/kappale/tootoo/3">Tootoo ja vapaaehtois Apu!</a></li>
      <li><a href="/kappale/tootoo/4">Tootoon metsäretki</a></li>
      <li>
        <a href="/kappale/tootoo/5">Tootoon äiti Lauloo ja hänen hillonsa</a>
      </li>
      <li>
        <a href="/kappale/tootoo/6"
          >Tootoolla sisko on nimeltänsä Kaatoo ja heidän kauppareissu</a
        >
      </li>
      <li>
        <a href="/kappale/tootoo/7">Joulu tulee Tootoonkin pikkukylään</a>
      </li>
      <li>
        <a href="/kappale/tootoo/8">Kirje sukulaiselle nimeltänsä Tahtoo</a>
      </li>
      <li>
        <a href="/kappale/tootoo/9">Tootoo ja lempiväiset</a>
      </li>
      <li><a href="/kappale/tootoo/10">Tootoon perheen saunareissu</a></li>
      <li><a href="/kappale/tootoo/11">Tootoo sirkuspellenä</a></li>
      <li><a href="/kappale/tootoo/12">Tootoon Nukkumatti</a></li>
      <li><a href="/kappale/tootoo/13">Tootoo rakentaa talon</a></li>
      <li><a href="/kappale/tootoo/14">Naapurin Maltti</a></li>
    </ul>
    <h2>Kirjan henkilöt ja nimet</h2>

    <h3>Tootoo</h3>
    <p>Reipas menninkäinen, kirjan päähenkilö</p>

    <h3>Kaatoo</h3>
    <p>Tootoon sisko</p>

    <h3>Lauloo</h3>
    <p>Tootoon äiti</p>

    <h3>Kauhoo</h3>
    <p>Tootoon isä</p>

    <h3>Sä, Toffee, Namu, Komu, Tantaraa</h3>
    <p>Tootoon ystäviä</p>

    <h3>Lilu & Loo Hiipi</h3>
    <p>Sä:n vanhemmat</p>

    <h3>Seljänne</h3>
    <p>Posteljooni</p>

    <h3>Jääpuikko</h3>
    <p>Tyttö, josta Tootoo vähän pitää</p>

    <h3>Jaskaset</h3>
    <p>Pändi</p>

    <h3>Polvikkaat</h3>
    <p>Ryhmän nimi, joka tarkoittaa punaisia housuja</p>

    <h3>Rollo</h3>
    <p>Liikkumaväline. Sun täytyy keksiä millanen</p>

    <hr />
    <div class="chapterfooter">
      <a href="/kappale/tootoo/0" class="next-chapter">
        <span class="desc">Seuraava&nbsp;&nbsp;&gt;</span>
        <span class="title">Tootoo ja vapaaehtois Apu!</span>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped="true">
@media screen {
  h3 {
    margin-bottom: 10px;
  }
  h3 + p {
    margin: 0 0 0 0;
  }
  ul {
    list-style-type: none;
    margin-block: 0;
    padding: 0;
  }
  li {
    color: #161616;
    font-family: "Caveat", cursive;
    font-size: 26px;
  }
  li a {
    color: #0300a3;
    font-family: "Caveat", cursive;
    font-size: 26px;
  }
  hr {
    margin-top: 60px;
  }
  .chapterfooter {
    display: flex;
    justify-content: right;
  }
  a.next-chapter {
    display: flex;
    flex-direction: column;
    height: 100px;
  }
  a .desc {
    font-family: "Dancing Script";
    font-size: 30px;
    text-decoration: none;
    color: #000000;
    letter-spacing: 0;
  }
  a .title,
  figcaption {
    color: #161616;
    font-family: "Caveat", cursive;
    font-size: 21px;
  }
  img {
    box-sizing: border-box;
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 650px) {
}
</style>
